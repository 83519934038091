// testnet

export const abrarContract = "0x6AaF4ce5d9d01Ec2fcbBDd6CeBd8Db41D9a8cd20";
export const NetworkContextName = "Ethereum Mainnet Network";
export const ACTIVE_NETWORK_ETH = 5;

// mainnet
// export const abrarContract = "0x7D9e7cA33fEA93477dcDf856656581F436639905";
// export const NetworkContextName = "Ethereum Mainnet Network";
// export const ACTIVE_NETWORK_ETH = 1;
// export const ACTIVE_NETWORK_BNB = 97;
export const RPC_URL =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
export const RPC_URL_BNB = "";
export const explorerLink = "https://goerli.etherscan.io/tx/";
